export const isObjEmpty = (obj) => {
  if (typeof obj === "undefined") return false
  if (obj === null) return true
  return obj && Object.keys(obj).length === 0
}

export const generateRandomString = (length) => {
  let text = ""
  const character =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (let i = 0; i < length; i += 1)
    text += character.charAt(Math.floor(Math.random() * character.length))

  return text
}

export const getDeviceId = new Promise((resolve) => {
  const deviceId = generateRandomString(36)
  if (typeof window !== "undefined" && window.requestIdleCallback) {
    requestIdleCallback(() => {
      resolve(deviceId)
    })
  } else {
    resolve(deviceId)
  }
})

export function formatedDateTime(date, time) {
  return new Intl.DateTimeFormat("id-ID", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Jakarta",
  })
    .format(new Date(date + "T" + time))
    .replace(/,(?=[^,]+$)/, " ∙")
}

export function formatedDate(date, time, showDay, isComa) {
  return new Intl.DateTimeFormat("id-ID", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "Asia/Jakarta",
  }).format(new Date(date))
}

export function formatedDateNoDay(
  date,
  isNumeric = false,
  connector = " ",
  isLongMonth = false
) {
  return new Intl.DateTimeFormat("id-ID", {
    year: "numeric",
    month: isNumeric ? "numeric" : !isLongMonth ? "short" : "long",
    day: "numeric",
    timeZone: "Asia/Jakarta",
  })
    .format(new Date(date))
    .replace(/[\s/]/g, connector)
}

export function formatedTime(date, time, is12 = true) {
  return new Intl.DateTimeFormat("id-ID", {
    hour12: is12,
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Jakarta",
  }).format(new Date(date + "T" + time))
}

export function urlToCampaign(string, index = 1) {
  return string.split("/")[index].replaceAll("/", "").replaceAll("-", " ")
}

export function underScoreToSpace(string) {
  return string.replaceAll("_", " ")
}

export const encryptGame = (data) => {
  console.log(data, "data encrypt")
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  console.log(dec)
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const statusConts = {
  info: "INFO",
  error: "ERROR",
  warning: "WARNING",
}

export const networkFlow = {
  request: "REQUESTING",
  received: "RECEIVED",
}

export function consoleHelper(status, data, networkFlow, naming) {
  if (
    process.env.NEXT_PUBLIC_IS_DEBUGGING === "false" &&
    status !== statusConts.error
  )
    return
  console.log(
    "================================",
    "\n",
    `[${status}]${naming ? `- ${naming} ` : " "}`,
    "\n",
    JSON.stringify(data),
    "\n",
    networkFlow,
    "\n",
    "================================"
  )
}

export function getEnvAsset(env) {
  switch (env) {
    case "DEV":
      return "dev"
    case "QA":
      return "qa"
    case "production":
      return "deep"
    default:
      return "deep"
  }
}

export const snackbarPoint = (point) => {
  const title =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo Berhasil Dapetin ${point} Poin`

  const subtitle =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Lanjut eksplor dan kumpulkan lebih banyak poin!`
      : `Eksplor lebih banyak tantangan buat dapet lebih banyak poin`
  return { title, subtitle }
}

export const urlAsset = () => {
  return process.env.NEXT_PUBLIC_STATIC_URL
}

export const postMessage = (message) => {
  window.parent.postMessage(
    btoa(JSON.stringify(message)),
    process.env.NEXT_PUBLIC_AG_DOMAIN
  ) // Target origin (replace with actual parent URL)
}

export const handleChances = (string, popupData) => {
  const availArray = string.split(" ")
  const chancesIndex = availArray.findIndex(
    (word) => word.toLowerCase() === "kesempatan"
  )
  if (chancesIndex !== -1) {
    if (
      !isNaN(availArray[chancesIndex - 1]) &&
      !isNaN(parseInt(availArray[chancesIndex - 1]))
    ) {
      availArray[chancesIndex - 1] = popupData?.remainingChances
    } else {
      availArray.splice(chancesIndex, 0, popupData?.remainingChances)
    }
    return availArray.join(" ")
  } else {
    return string
  }
}

export const toProxy = (url) => {
  return `https://api${process.env.NEXT_PUBLIC_ENVIRONMENT !== "production" ? ".dev" : ""}.oneux.id/api/fs/proxy-media/${String(
    url
  )
    .split("/")
    .slice(3)
    .join("/")}`
}

export const censorName = (name) => {
  return name
    .split(" ")
    .map((part) => {
      if (part.length <= 2) return part // If the part is very short, leave it as is
      const firstChar = part.charAt(0)
      const lastChar = part.charAt(part.length - 1)
      const middle = "*".repeat(part.length - 2)
      return `${firstChar}${middle}${lastChar}`
    })
    .join(" ")
}
